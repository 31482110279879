<template>
  <ExerciseComponent
    :user-i-d="$route.params.id"
    :amount="parseInt($route.query.amountp)"
  />
</template>
<script>
import ExerciseComponent from "./components/exerciseComponent.vue";

export default {
  components: {
    ExerciseComponent,
  },
  /* props: {
    getUserID: {
      type: String,
      required: true,
    },
    getAmount: {
      type: Number,
      required: true,
      default: 1,
    },
  }, */
  data() {
    return {};
  },
  computed: {
    //
  },
  watch: {
    //
  },
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
  setup() {
    //
  },
};
</script>
