module.exports = (data, iconPath) => {
	return new Promise((resolve, reject) => {
		if (!Array.isArray(data)) {
			data = [data];
		}
		exercises = "";
		data.forEach(element => {
			const {
				exerciseLogo,
				instruction,
				instructionQR,
				icons,
				subject,
				number,
				id,
				question,
				qrLink,
				nameAttach,
				mistakeAvg,
				timeAvg,
			} = element;

			exercises += `<div class="exercise-wrapper" style="display:none">
      <div class="page-wrapper">
            <div class="page-top">
                <div class="logo-icon">
                    <img src="${`${iconPath + exerciseLogo}.png`}">
                </div>
                <div class="top-row-par">
                    <div class="top-row-1">
                        <div class="name-area">
                            <p>${subject.name}<br>${nameAttach}</p>
                        </div>
                        <div class="description-area">
                            <p>${instruction}</p>
                        </div>
                    </div>
                    <div class="icon-area">
                        <div class="icon">
                           <img src= "${`${iconPath + icons[0]}.jpg`}">
                        </div>
                        <div class="icon">
                           <img src= "${`${iconPath + icons[1]}.jpg`}">
                        </div>
                        <div class="icon">
                           <img src= "${`${iconPath + icons[2]}.jpg`}">
                        </div>
                        <div class="icon">
                           <img src= "${`${iconPath + icons[3]}.jpg`}">
                        </div>
                        <div class="icon">
                           <img src= "${`${iconPath + icons[4]}.jpg`}">
                        </div>
                        <div class="icon">
                           <img src= "${`${iconPath + icons[5]}.jpg`}">
                        </div>
                        <div class="icon">
                           <img src= "${`${iconPath + icons[6]}.jpg`}">
                        </div>
                        <div class="icon">
                           <img src= "${`${iconPath + icons[7]}.jpg`}">
                        </div>
                        <div class="icon">
                           <img src= "${`${iconPath + icons[8]}.jpg`}">
                        </div>
                    </div>
                </div>
                <div class="qr-area">
                    <div class="qr-label-text">ANLATIM</div>
                    <div id="triangle" style="border-color: rgb(0, 0, 0) transparent transparent; border-width: 3mm 6mm 0px;"></div>
                   <div id="${id}" class="QR" data-url="${instructionQR}"></div>
                </div>
            </div>
            <div class="excercise-area">
               <img src="${`${iconPath + question}.jpg`}">
            </div>
            <div class="footer-area">
                <div class="excercise-id">
                    <div class="excercise-fields">
                        <div class="sn-label">E. Kod:</div>
                        <div class="exc-input subject-id">
                            ${number}
                        </div>
                    </div>
                    <div class="excercise-fields">
                        <div class="en-label">S. Ort:</div>
                        <div class="exc-input">
                            ${timeAvg || "Ø"}
                        </div>
                    </div>
                    <div class="excercise-fields-end">
                        <div class="kn-label">H. Ort:</div>
                        <div class="exc-input">
                            ${mistakeAvg || "Ø"}
                        </div>
                    </div>
                </div>
                <div class="time-input">
                    <div class="time-text"> SÜRE </div>
                    <div class="time-input-area time-inputs">
                        <div class="min-text">0-1 DK</div>
                        <div class="min-text">1-2 DK</div>
                        <div class="min-text">2-3 DK</div>
                    </div>
                    <div class="time-input-area pre-letter">
                        <div class="min-text min-cat">A</div>
                        <div class="min-text min-cat">B</div>
                        <div class="min-text min-cat">C</div>
                    </div>
                    <div class="time-input-area time-inputs">
                        <div class="min-text">3-4 DK</div>
                        <div class="min-text">4-5 DK</div>
                        <div class="min-text"><p>5<sup>+</sup>DK</p></div>
                    </div>
                    <div class="time-input-area pre-letter">
                        <div class="min-text min-cat">D</div>
                        <div class="min-text min-cat">E</div>
                        <div class="min-text min-cat">F</div>
                    </div>
                </div>
                <div class="answer-input">
                    <div class="time-text">HATA</div>
                    <div class="time-input-area time-inputs">
                        <div class="min-text">0 HT</div>
                        <div class="min-text">1 HT</div>
                        <div class="min-text">2 HT</div>
                    </div>
                    <div class="time-input-area pre-letter">
                        <div class="min-text min-cat">A</div>
                        <div class="min-text min-cat">B</div>
                        <div class="min-text min-cat">C</div>
                    </div>
                    <div class="time-input-area time-inputs">
                        <div class="min-text">3 HT</div>
                        <div class="min-text">4 HT</div>
                        <div class="min-text"><p>5<sup>+</sup>HT</p></div>
                    </div>
                    <div class="time-input-area pre-letter">
                        <div class="min-text min-cat">D</div>
                        <div class="min-text min-cat">E</div>
                        <div class="min-text min-cat">F</div>
                    </div>
                </div>
                <div class="is-time">
                    <div class="time-icon">Ø</div>
                    <div class="is-time-text">VSG</div>
                </div>
                <div class="qr-area">
                    <div class="qr-label-text">VERİ GİRİŞ</div>
                    <div class="qr-label-text">${id}</div>
                   <div id="${qrLink}" class="QR" data-url="https://app.dikotr.com/live-exercise-entry/${id}"></div>
                </div>
                
            </div>
        </div></div>`;
		});
		resolve(exercises);
	});
};
