<template :id="propX" />
<script>
import axios from "@axios";
import QRCodeStyling from "qr-code-styling";
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: {
    userID: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      moved: false,
      title: "",
    };
  },
  computed: {
    //
  },
  watch: {
    // ["options.data"]: function () {
    //   this.qrCode.update(this.options);
    // },
  },
  created() {
    window.imgTotal = 10;
    window.imgNow = 0;
    window.qrCodes = [];
  },
  mounted() {
    // (`${path.list + this.$route.params.id}`)
    // document.addEventListener("change", () => {
    //   if ((window.imgNow = window.imgTotal)) {
    //     window.print();
    //   }
    // });
    window.addEventListener("afterprint", function () {
      window.close();
    });
    let title = "";
    // const iconPath = "http://localhost:3000/images/";
    // const iconPath = "http://213.238.181.188:3000/images/";
    const iconPath = axios.defaults.baseURL + "/images/";
    let axiosRoute = ``;
    // console.dir(this.$route);
    if (this.$route.query.answer != undefined) {
      title = "ANSWER";
      if (this.$route.query.session != undefined || this.$route.query.session != null) {
        axiosRoute = `${`exercises/reprint/${this.userID}?session=${this.$route.query.session}&answer=1`}`;
      } else {
        axiosRoute = `${`exercises/reprint/${this.userID}?id=${this.$route.query.exercise}&answer=1`}`;
      }
    } else {
      title = "EXERCISE";
      if (this.$route.query.reprint != undefined) {
        if (this.$route.query.session != undefined || this.$route.query.session != null) {
          axiosRoute = `${`exercises/reprint/${this.userID}?session=${this.$route.query.session}`}`;
        } else {
          axiosRoute = `${`exercises/reprint/${this.userID}?id=${this.$route.query.exercise}`}`;
        }
      } else if (
        this.$route.query.session != undefined ||
        this.$route.query.session != null
      ) {
        if (this.$route.query.amount != undefined || this.$route.query.amount != null) {
          `${`exercises/${this.userID}?amount=${this.amount}&session=${this.$route.query.session}`}`;
        } else {
          axiosRoute = `${`exercises/${this.userID}?session=${this.$route.query.session}`}`;
        }
      } else {
        axiosRoute = `${`exercises/${this.userID}?amount=${this.amount}`}`;
      }
    }
    history.replaceState(
      { id: "0", source: "web" },
      "EGZERSIZ",
      `${window.location.origin}`
    );

    const exerciseParse = require("./exerciseParser.js");
    const exerciseStyle = require("./style.js");
    axios.post("/" + axiosRoute).then((res) => {
      document.getElementById("loading-bg").style.display = "block";

      const wrapper = document.getElementById("app");
      wrapper.className = "";
      exerciseParse(res.data, iconPath).then((parse) => {
        wrapper.innerHTML = parse;

        // return;
        const pageImgs = document.querySelectorAll("img:not([src='/logo.png'])");
        window.imgTotal = pageImgs.length;

        // document.addEventListener("mousemove", function () {
        // if (this.moved) return;
        // this.moved = true;
        // console.log(this.moved);
        // console.log("mousemove");
        const qrCodes = document.querySelectorAll(".QR");
        qrCodes.forEach(function (qrCode) {
          console.log("qrcode start");
          // console.log(qrCode.dataset.url);
          // console.log("width: " + qrCode.offsetWidth, "height: " + qrCode.offsetHeight);
          // console.log(
          //   "myWidth: " + qrCode.style.width,
          //   "myHeight: " + getComputedStyle(qrCode).height
          // );
          const qrC = new QRCodeStyling({
            width: 300,
            height: 300,
            data: qrCode.dataset.url,
            margin: 0,
            padding: 0,
            qrOptions: {
              typeNumber: "0",
              mode: "Byte",
              errorCorrectionLevel: "Q",
            },
            imageOptions: { hideBackgroundDots: true, imageSize: 0.3, margin: 0 },
            dotsOptions: { type: "dots", color: "#000000" },
            backgroundOptions: { color: "#ffffff" },
            dotsOptionsHelper: {
              colorType: { single: true, gradient: false },
              gradient: {
                linear: true,
                radial: false,
                color1: "#6a1a4c",
                color2: "#6a1a4c",
                rotation: "0",
              },
            },
            cornersSquareOptions: { type: "dot", color: "#000000" },
            cornersSquareOptionsHelper: {
              colorType: { single: true, gradient: false },
              gradient: {
                linear: true,
                radial: false,
                color1: "#000000",
                color2: "#000000",
                rotation: "0",
              },
            },
            cornersDotOptions: { type: "", color: "#000000" },
            cornersDotOptionsHelper: {
              colorType: { single: true, gradient: false },
              gradient: {
                linear: true,
                radial: false,
                color1: "#000000",
                color2: "#000000",
                rotation: "0",
              },
            },
            backgroundOptionsHelper: {
              colorType: { single: true, gradient: false },
              gradient: {
                linear: true,
                radial: false,
                color1: "#ffffff",
                color2: "#ffffff",
                rotation: "0",
              },
            },
            image: `${iconPath + "dikoicomono.png"}`,
          });
          // use the qr-code-styling library to create a qr-code
          // console.log(qrC, qrCode);
          qrC._canvas.style.width = "75px";
          qrC._canvas.style.height = "75px";
          qrC._canvas.style.margin = "5px";
          qrC.append(qrCode);
          console.log("qrcode appended");
          window.qrCodes.push(qrC._canvasDrawingPromise);
        });
        // });
        document.addEventListener("imageLoaded", function () {
          window.imgNow++;
          // console.log("image loaded", window.imgNow, window.imgTotal);
          if (window.imgNow == window.imgTotal) {
            Promise.all(window.qrCodes).then(() => {
              console.log("printing");
              const exercises = document.querySelectorAll(".exercise-wrapper");
              for (let i = 0; i < exercises.length; i++) {
                exercises[i].style.display = "block";
              }
              document.getElementById("loading-bg").style.display = "none";
              document.getElementsByTagName("head")[0].innerHTML = exerciseStyle;
              document.title = title;
              if (!document.title.match(/ANSWER/g)) {
                window.print();
              }
            });
          }
        });
        pageImgs.forEach(function (img) {
          // if (img.complete) {
          // let imgEvent = new Event("HTMLEvents");
          // imgEvent.initEvent("imageLoaded", true, true);
          // imgEvent.eventName = "imageLoaded";
          // document.dispatchEvent(imgEvent);
          // } else {
          img.addEventListener("load", function () {
            let imgEvent = new Event("HTMLEvents");
            imgEvent.initEvent("imageLoaded", true, true);
            // imgEvent.eventName = "imageLoaded";
            document.dispatchEvent(imgEvent);
          });
          // }
        });
      });
    });
    // function QRFILL() {
    //   }

    // QRFILL();
  },
  methods: {
    //
  },
  setup() {
    //
  },
};
</script>
<style scoped></style>
