module.exports = `
<script type="text/javascript" src="https://unpkg.com/qr-code-styling@1.5.0/lib/qr-code-styling.js"></script>
<style scoped>
@import url('http://fonts.cdnfonts.com/css/swiss-721-3');
*{
  margin: 0;
  padding: 0;
  font-family: 'Swiss 721', sans-serif;
}
@page {
  size: A4;
  margin: 0;
},
 @media print {
  *, body, .exercise-wrapper {
    margin: 0;
    padding: 0;
    box-shadow: 0;

  }
} 
.exercise-wrapper{
  height: 297mm;
  width: 210mm;
}
.page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
}

.page-top {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
}

.headline {
  padding: 0%;
  margin: 0%;
}

.description-area p {
  margin: 4px;
  padding: 0;
  margin-left: 4.65mm;
  font-size: 10pt;
}

.name-area p {
  margin: 4px;
  padding: 0;
  margin-left: 4.65mm;
  font-size: 10pt;
}

.icon {
  margin: 4px;
  /* 4px if with border*/
  padding: 0%;
  /*border: 1px solid black;*/
  border-radius: 10px;
  width: 50px;
  height: 50px;
}
.icon img{
  width: 100%;
  height: 100%;
}

.headline-row-1 {
  padding: 0%;
  margin: 0%;
  height: 50px;
}

.headline-row-2 {
  padding: 0%;
  margin: 0%;
  height: 50px;
}

.excercise-area {
  padding: 0%;
  /*border: 1px solid black;*/
  height: 692px;
  width: 692px;
}

.footer {
  padding: 0%;
  margin: 0%;
}

.footer-area {
  display: flex;
  justify-content: space-between;
  padding: 0%;
  margin: 0%;
  height: 115px;
  width: 692px;
}

.excercise-id {
  width: 148px;
  height: 114px;
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 4px;
}

.is-time {
  display: flex;
  flex-direction: column;
  width: 38px;
  height: 114px;
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 4px;
}

.time-input {
  display: flex;
  flex-direction: row;
  width: 181px;
  height: 114px;
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 4px;
}

.answer-input {
  display: flex;
  flex-direction: row;
  width: 47.57mm;
  height: 30.237mm;
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 4px;
}

.is-time-text {
  height: 20.16mm;
 
    writing-mode: tb-rl;
        transform: rotate(-180deg);
  font-size: 10pt;
  line-height: 0.5;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-icon {
  height: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-bottom: 1pt solid black;
}

.time-text {
  width: 7.25mm;
  height: 100%;
  /* border-right: 1px solid black; */
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  font-size: 12px;
  line-height: 0.5;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.min-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 33.33%;
  /* border-left: 1px solid black; */
  border-bottom: 1px solid black;
  text-align: center;
  
  font-size: 10pt;
}
.min-cat{
  font-weight: bold;
  font-size: 11pt;
}

:last-child {
  border-bottom: none;
}

.time-input-area {
  width: 26.87%;
  border-left: 1px solid black;

}

/* .time-text {
  width: 11.59%;
} */

.time-inputs {
  width: 26.87%;
}

.pre-letter {
  width: 16.65%;
}

.answer-input {
  width: 152px;
  height: 114px;
  border: 1px solid black;
  border-radius: 10px;
}

.footer-qrcode {
  border-radius: 10px;
  border: 1px solid #000;
  height: 30.237mm;
  width: 22.495mm;
  display: flex;
  flex-direction: column;
}

.excercise-fields {
  width: 100%;
  height: 33.3%;
  border-bottom: 1px solid black;
  display: flex;
}

.excercise-fields-end {
  width: 100%;
  height: 9.8mm;
  display: flex;
}

.sn-label {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 14.27mm;
  border-right: 1px solid black;
  font-size: 9pt;
  font-weight: bold;
}

.en-label {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 14.27mm;
  border-right: 1px solid black;
  font-size: 9pt;
  font-weight: bold;
}

.kn-label {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 14.27mm;
  border-right: 1px solid black;
  font-size: 9pt;
  font-weight: bold;
}

.footer-row {
  padding: 0%;
  margin: 0%;
}

.footer-col {
  padding: 0%;
  margin: 0%;
}

.instruction-icon {
  width: 50px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 10px;
}

.name-area {
  margin: 4px;
  padding: 0%;
  width: 59.985mm;
  height: 14.763mm;
  border-radius: 10px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
}

.description-area {
  margin: 4px;
  padding: 0%;
  width: 75.157mm;
  height: 14.763mm;
  border-radius: 10px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
}

.logo-icon {
  height: 30.237mm;
  width: 19.505mm;
  border: 1px solid #000;
  border-radius: 10px;
  margin: 4px 4px 4px 0px;
  padding: 0%;
}

.logo-icon img {
  width: 100%;
}

.qr-label-text {
  font-size: 8pt;
  font-weigt: bold;
  height: 3.71mm;
  width: 100%;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size:6pt;
  font-weight: bold;
}

.qr-area-zone {
  width: 100%;
  height: 85%;
}

.qr-area {
  border-radius: 10px;
  border: 1px solid #000;
  height: 30.237mm;
  width: 22.495mm;
  margin:4px 0px 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-area img {
  width: 95%;
  height: 75%;
  margin-top: 5%;
}

.footer-qrcode img {
  width: 95%;
  height: 85%;
  margin-top: 5%;
}

.qr-triangle {
  width: 0;
  height: 0;
  margin-left: 13px;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid black;
}

.qr-label {
  border-bottom: 1px solid black;
  /*width: 85px;*/
  height: 14px;
  font-size: 6pt;
}

.top-row-par {
  width: 65.258%;
  display: flex;
  flex-direction: column;
}

.top-row-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.icon-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

img {
  display: auto;
  margin: auto;
  height: 100%;
  width: 100%;
}

.exc-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align:center;
  width: 24.96mm;
  margin-left: 4px;
  margin-right: 4px;
}

.subject-id {
  
  word-wrap: break-word;
}
#triangle {
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    border-style: solid;
    width: 0px;
    height: 0px;
    line-height: 0px;
    border-width: 0px 100px 100px 100px;
    border-color: transparent transparent #007bff transparent;
    _border-color: #000 #000 #007bff #000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}
.qr-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.qr-label-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.QR {
  width: 85px;
  height: 85px;
}
</style>
`;
